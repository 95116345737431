.root {
  cursor: pointer;
}
.projectTyleSelectorSelected {
  border: 3px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
}

.projectTyleSelectorNone {
  border: 3px solid var(--accent-color);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
}

.projectTyleSelectorDisabled {
  border: 3px solid var(--utility-gray);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
}

.selectorImg {
  width: 2 !important;
  height: 2 !important;
}

.title {
  font-size: 18px;
  color: var(--primary-dark-green) !important;
  padding: 0;
  margin: 4px;
}

.description {
  font-size: 13px;
  color: var(--primary-gray-light) !important;
  padding: 0;
  margin: 4px;
}

.selectCont {
  display: flex;
  padding: 8px;
  padding-left: 12px;
  justify-content: space-around;
  align-items: center;
}
.textCont {
  min-width: 220px;
}
.flexSpacer {
  flex-grow: 1;
}
.iconMain {
  text-align: right;
  align-items: flex-end;
}
