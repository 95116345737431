.root {
  margin: 10px 0;
  height: 100%;
  overflow-wrap: break-word;
}
.innerRoot {
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 10px;
  height: 100%;
}

.moreIconHolder {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.projectPhoto {
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: center;
  height: 50%;
  width: 50%;
  margin: auto;
  padding-top: 20px;
}
.data {
  padding: 0 10px 0 10px;
  margin: auto;
}
.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-gray);
  padding-top: 5px;
  text-align: center;
}

.iconContainer {
  text-align: center;
  padding-top: 16px;
}

.icon {
  color: var(--primary-gray);
  height: 40px !important;
  width: 40px !important;
}

.folderActions {
  text-align: center;
}
.formRoot {
  display: flex;
}
