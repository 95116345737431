.circleBtn {
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
}

.backArrow {
  color: var(--utility-White);
}

.bg1,.bg2 {
  position: absolute;
  /* width: 100vw; */
  /* height: 100vh; */
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
}

.bg{
  background: #F0F0F0;
  border-radius: 15px;
  /* width: 98vw; */
  height: 96vh;
}

.bg3 {
  /* background-image: url("../../../assets/images/brand_pattern1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  display: flex;
  padding-top: 40px 40px 40px 40px;
}

@media only screen and (max-width: 1000px) {
  .bg2 {
    display: none;
  }

  .bg3 {
    display: block;
  }
}

@media only screen and (min-width: 1001px) {
  .bg2 {
    display: block;
  }

  .bg3 {
    display: none;
  }
}


.authContainer {
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  /* width: calc(100% - 200px); */
  padding-top: 20px;
  float: left;
  /* height: 40vh; */
  height: 467px;
  margin: auto;
}

.createAccCont {
  vertical-align: bottom;
  height: 100%;
  /* position: relative; */
  /* left: 80%; */
  top: 50%;
  /* width: 180px; */
  text-align: right;
  /* transform: translateY(-50px); */
}

.createAccCont p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #cdeefa;
}

.createAccCont span {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #cdeefa;
}

.createAccContMobi {
  vertical-align: bottom;
  height: 100%;
  /* position: relative; */
  /* left: 80%; */
  top: 50%;
  /* width: 180px; */
  text-align: center;
  /* transform: translateY(-50px); */
}

.createAccContMobi p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #06454d;
}

.createAccContMobi span {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #06454d;
}

.logoWithName {
  background-image: url("../../../assets/images/Smartlife-invert-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  margin-top: 40px;
  width: 220px;
  height: 80px;
}

.titleDarkText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.5;
}

.titleLightText {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;

  color: #ffffff;
}

/* .bg2 {
  background-image: url("../../../assets/images/brand_pattern1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.mainBg {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url("../../../assets/images/brand_pattern-copy.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.leftSection {
  float: left;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
  /* margin: 100px 60px 100px 60px; */
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: auto;
  min-height: 300px;
}

.rightSection {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 5px 25px rgba(4, 134, 150, 0.1);
  border-radius: 10px;
  /* width: fit-content; */
  /* float: right; */
  padding: 25px 82px 0px 82px;
  border: 2px solid #fefefe;
  min-width: 473px;
  transform: translateY(-109px);
  text-align: center;
  height: 642px;
  /* margin: auto; */
  /* margin-right: 200px; */
}

.mobileCon{
  background: #ffffffe3;
  box-shadow: 0px 5px 25px rgba(4, 134, 150, 0.1);
  padding: 25px 30px;
  margin: 25px 30px;
}

@media only screen and (max-width: 1920px) {
  .bg1 {
    min-width: 1470px !important;
  }
}

.createAccount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountCreate {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  margin-right: -24px;
  z-index: 1;
  margin-top: -223px;
  min-width: 215px;
}

.accountText {
  padding-right: 15px;
}

.notAccount {
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: var(--utility-White);
}

.account {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: var(--utility-White);
}

@media only screen and (min-width: 2560px) {
  .bg1 {
    width: 100vw;
  }
}

@media only screen and (max-width: 1440px) {
  .bg1 {
    min-width: 1321px !important;
    margin: 0px 60px 0px 60px;
  }
}

@media only screen and (max-width: 1440px) {
  .bg {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .bg3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .bg1 {
    margin: 30px !important;
  }
  .rightSection, .mainBg {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .bg {
    margin-left: 0px;
    margin-right: 0px;
  }
}