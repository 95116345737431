:root {
  /**
    All color variables from Figma ui
  */
  --main-cont-bg-gradient: linear-gradient(
    239.95deg,
    #048696 7.54%,
    #06454d 92.1%
  );
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
  --primary-color: #048696;
  --primary-dark-green: #06454d;
  --primary-dark-green-light: #06454d4f;
  --light-green: #048696;
  --accent-primary: #cf0b0b;
  --utility-black: #021518;
  --utility-dark-gray: #354052;
  --utility-gray: #818181;
  --utility-gray1: #8f8f8f8f;
  --utility-gray-text: #7f8fa4;
  --utility-gray-light: #e2e2e2;
  --utility-background: #f0f0f0;
  --utility-White: #ffffff;
  --accent-color: #cdeefa;
  --primary-gradient-black: linear-gradient(
    62.87deg,
    #021518 6.27%,
    #05363d 89.75%
  );
  --light-green-1: #d9dfe0;
  --green-10: #e6f3f4;
  --green-30: #b4dbdf;
  --green-90: #06454d;
  --light-green-gradient: linear-gradient(180deg, #e6f3f4 0%, #fbffff 100%);
  --green-light-bg: #ebf1f2;
  --disabled-btn-bg: #f9f9f9;

  --button-boarder: #06454d;
}
