.DragSelectorListCont {
  padding: 8px;
}
.DragSelectorListInnerCont {
  background: #fefefe;
  width: 100%;
  min-height: 90px;
  padding-bottom: 25px;
}

.spacer {
  flex-grow: 1;
}
.DragSelectorItemCard {
  color: #aa3838;
  cursor: pointer;
  padding: 8px;
  background: #fefefe;
  display: flex;
}

.DragSelectorItemCard:hover {
  background: var(--light-green);
}
.DragSelectorItemCardDivider {
  padding: 1px 0px 0px;
  background: #888888;
}
.title {
  font-size: 12px;
  margin: 0px;
}

.subTitle {
  color: #888888;
  font-size: 10px;
}
.dragHere {
  text-align: center;
  font-size: 11px;
  color: #bebebe;
  padding: 8px 0px 4px 0px;
  margin: 0px;
}
.contSubTitle {
  color: var(--primary-color);
  font-size: 12px;
  margin: 0px !important;
  padding-bottom: 8px;
}
