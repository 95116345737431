.innerPageContent {
  display: flex;
}

.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}
.fieldSpace {
  margin: 8px 0 8px 0 !important;
  width: 100%;
}
.custom{
  height: 200px !important;
}
.thumbsContainer{
  display: 'flex';
  flex-direction: 'row';
  flex: 'wrap';
  margin-top: 16;
}

.thumb{
  display: 'inline-flex';
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: 'border-box';
};

.thumbInner {
  display: 'flex';
  min-width: 0;
  overflow: 'hidden';
};

.img{
  display: 'block';
  width: 'auto';
  height: '100%';
};

.projectPhotom {
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 200px !important;
  width: 80% !important;
  border-right: 2px solid #e6f3f4;
  border-radius: 10px;
  margin: 0px 20px;
}

.dropzone {
  height: 40vh !important;
  background-color: rebeccapurple;
}
