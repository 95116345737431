.root {
  position: relative;
  width: 100%;
  height: 100vh;
  /* height: 100% !important; */
  /* min-height: 96vh !important; */
  /* padding: 2vh 2vh; */
  background: linear-gradient(62.87deg, #021518 6.27%, #05363D 89.75%);
}

a {
  text-decoration: none;
  color: #fefefe;
}
.route-cont {
  padding: 8px;
}

.MuiOutlinedInput-input {
  font-size: 1em !important;
  height: 12px !important;
  padding: 12px 8px;
}
