.root {
  cursor: pointer;
  /* min-height: 25vh;
  width: 90%;
  padding-left: 20px !important;
  margin-top: 10% !important; */
  height: 100%;
}



.projectTyleSelectorSelected {
  border: 3px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
}

.projectTyleSelectorNone {
  border: 3px solid var(--accent-color);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
  position: relative;
}

.projectTyleSelectorDisabled {
  border: 3px solid var(--utility-gray);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
}

.selectorImg {
  width: 2 !important;
  height: 2 !important;
}

.title {
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-dark-green) !important;
  overflow: hidden;
}

@media only screen and (min-width: 600px) {
  .title {
    line-height: 28px;
    font-size: 18px;
    font-weight: 600;
    padding-right: 80px;
    color: var(--primary-dark-green) !important;
  }
}

.description {
  font-size: 11px;
  color: var(--utility-gray-text) !important;
  padding: 0;
  margin-top: -6% !important;
  overflow: hidden;
}

.selectCont {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  padding: 8px;
  padding-left: 12px;
  padding-bottom: 0;
  height: 100%;
}
.textCont {
  /*min-width: 220px;*/
}
.flexSpacer {
  flex-grow: 1;
}
.iconMain {
  text-align: right;
  align-items: flex-end;
}

.notSelected {
  position: relative;
}

/* .notSelectedIconShadow { 
  background-color: var(--utility-gray-light);
  position: absolute;
  height: 35px;
  width: 35px;
  border-radius: 25px;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
} */

/* .iconStyle {
  box-shadow: 0px 0px 30px 20px;
} */
