.root {
  border: 1px solid #7f8fa4;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  margin: 0 0 8px 0;
  padding: 8px;
  display: flex;
  flex-flow: wrap;
  overflow-y: scroll;
}
.iconRoot {
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 200px;
  margin: 0 0 8px 0;
  padding: 8px 0;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  overflow-y: scroll;
}
.iconItem {
  border: 1px solid #7f8fa4;
  border-radius: 5px;
  margin: 8px;
  padding: 8px;
  cursor: pointer;
  max-height: 60px;
}
.calcIconView:before {
  color: var(--primary-color);
  font-size: 35px;
}
.calcIconViewSelected:before {
  color: var(--primary-color);
  font-size: 35px;
}

.iconItem:hover {
  background-color: var(--primary-dark-green);
}
.iconItem:hover .calcIconView:before {
  color: var(--primary-color);
}

.activeIcon {
  background-color: var(--primary-dark-green) !important;
}
.activeIcon .calcIconView:before {
  color: var(--primary-color) !important;
}

.selectedIcon {
  text-align: center;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 16px;
  padding: 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.iconSelectorRow {
  display: flex;
}
.iconInvalid {
  color: var(--utility-White);
}
.colorItem {
  padding: 10px;
  border: 1px solid #000000;
}
