.root {
  display: flex;
  flex-direction: column;

  margin: 8px 8px 8px 0;
  padding: 8px;
}
.root-border {
  border: 1px solid #7f8fa4;
  border-radius: 5px;
}
.scrollList {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
}
.label {
  padding-bottom: 8px;
}
.searchButton {
  padding-bottom: 8px;
}
.itemCatPath {
  font-size: 11px;
  padding-left: 8px;
}
.selectBar {
  display: flex;
}
