.innerPageContent {
  display: flex;
}
.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}

@media (max-width: 1030px) {
  .tabsTop {
    width: 65vw;
  }
}
