.root {
    padding: 10px 0 10px 0 !important;
    margin: auto;
    border-bottom: 1px solid var(--green-30);
}
.root:hover{
    cursor: pointer;
    /* box-shadow: 5px 5px 5px rgba(102, 163, 97, 0.233); */
}

.logHeader {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--utility-dark-gray);
  }
  
.logDate {
    font-style: normal;
    font-size: 11px;
    line-height: 13px;
    color: #7F8FA4;
    border-radius: 12px;
    padding-bottom: 9px !important;
  }
