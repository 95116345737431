.title {
  border: 1px solid #7f8fa467;
  border-radius: 0;
  margin: 4px;
  padding: 4px;
  border-radius: 4px;
}
.flexRow {
  display: flex;
}
.flexSpacer {
  flex-grow: 1;
}
