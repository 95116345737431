.projectTyleSelectorSelected{
  border: 3px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
  width: 95%;
  height: 60px;
}

.projectTyleSelectorNone{
  border: 3px solid var(--accent-color);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
  width: 95%;
  height: 60px;
}

.projectTyleSelectorDisabled{
  border: 3px solid var(--utility-gray);
  border-radius: 5px;
  padding: 5px 5px 5px 0px;
  margin: 5px 5px 5px 0px;
  width: 95%;
  height: 60px;
}

.selectorImg{
  width: 2 !important;
  height: 2 !important;
}

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
