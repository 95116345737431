.calc-icon-uv-primary:before {
  color: var(--primary-color);
  font-size: 43px;
}
.calc-icon-uv-primary {
  padding: 10px;
  /* padding-left: 30px; */
}

.root-border {
  border: 1px solid #7f8fa4;
  border-radius: 5px;
}

.uv-item-selected {
  background: #e4e4e4 !important;
}
