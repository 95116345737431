.root {
  border: 5px solid var(--primary-dark-green);
  border-radius: 5px;
  cursor: pointer;
  padding: 13px 0px 13px 0;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
}

.btnTextGreen {
  color: var(--primary-dark-green);
  display: inline;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  margin: 0 10px;
  text-transform: none;
}
.btnTextRed {
  color: var(--accent-primary);
  display: inline;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  margin: 0 10px;
  text-transform: none;
}

.iconTextGreen {
  margin-left: 10px;
  color: var(var(--primary-dark-green));
  /* transform: translateY(4px); */
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.iconTextRed {
  margin-left: 10px;
  color: var(--accent-primary);
  /* transform: translateY(4px); */
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.btnBgGreen {
  border: 1px solid var(--primary-dark-green) !important;
  border-radius: 5px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
  color: var(--primary-dark-green);
  height: 45px;
  padding: 0 20px;
  width: 100%;
}

.btnBgRed {
  border: 1px solid var(--accent-primary) !important;
  border-radius: 5px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
  color: var(--accent-primary);
  height: 45px;
  padding: 0 20px;
  width: 100%;
}

.buttonChildContainer {
  display: flex !important;
  flex-direction: row !important;
  /* justify-content: center; */
  align-items: center !important;
}
