@font-face {
  font-family: "calculus-icon-pack";
  src: url("fonts/calculus-icon-pack.eot?53ql5h");
  src: url("fonts/calculus-icon-pack.eot?53ql5h#iefix")
      format("embedded-opentype"),
    url("fonts/calculus-icon-pack.ttf?53ql5h") format("truetype"),
    url("fonts/calculus-icon-pack.woff?53ql5h") format("woff"),
    url("fonts/calculus-icon-pack.svg?53ql5h#calculus-icon-pack") format("svg");
  font-weight: normal;
  font-style: normal;
}

.calc {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "calculus-icon-pack" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.calc_output:before {
  content: "\e900";
  color: #fff;
}
.calc_search-bold:before {
  content: "\e901";
  color: #fff;
}
.calc_speaker:before {
  content: "\e902";
  color: #fff;
}
.calc-ac1-active:before {
  content: "\e903";
  color: #fff;
}
.calc-ac1:before {
  content: "\e904";
  color: #fff;
}
.calc-ac2-active:before {
  content: "\e905";
  color: #fff;
}
.calc-ac2:before {
  content: "\e906";
  color: #fff;
}
.calc-ac3-active:before {
  content: "\e907";
  color: #fff;
}
.calc-ac3:before {
  content: "\e908";
  color: #fff;
}
.calc-add-circle:before {
  content: "\e909";
  color: #14537f;
}
.calc-add:before {
  content: "\e90a";
  color: #00afc1;
}
.calc-addhub .path1:before {
  content: "\e90b";
  color: rgb(0, 175, 193);
}
.calc-addhub .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-addhub .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-alarm1-active:before {
  content: "\e90e";
  color: #fff;
}
.calc-alarm1:before {
  content: "\e90f";
  color: #fff;
}
.calc-alarm2-active:before {
  content: "\e910";
  color: #fff;
}
.calc-alarm2:before {
  content: "\e911";
  color: #fff;
}
.calc-alarm3-active:before {
  content: "\e912";
  color: #fff;
}
.calc-alarm3:before {
  content: "\e913";
  color: #fff;
}
.calc-alert-circle:before {
  content: "\e914";
  color: #07b665;
}
.calc-apple-tv1:before {
  content: "\e915";
  color: #fff;
}
.calc-apple-tv2:before {
  content: "\e916";
  color: #fff;
}
.calc-apple-tv3:before {
  content: "\e917";
  color: #fff;
}
.calc-apple-tv4:before {
  content: "\e918";
  color: #fff;
}
.calc-apple-tv5:before {
  content: "\e919";
  color: #fff;
}
.calc-apple-tv6:before {
  content: "\e91a";
  color: #fff;
}
.calc-apple-tv7:before {
  content: "\e91b";
  color: #fff;
}
.calc-apple-tv8:before {
  content: "\e91c";
  color: #fff;
}
.calc-applelogo:before {
  content: "\e91d";
  color: #fff;
}
.calc-back-166:before {
  content: "\e91e";
  color: #fff;
}
.calc-back-187:before {
  content: "\e91f";
  color: #fff;
}
.calc-backward:before {
  content: "\e920";
  color: #fff;
}
.calc-batteries1:before {
  content: "\e921";
  color: #fff;
}
.calc-batteries2:before {
  content: "\e922";
  color: #fff;
}
.calc-batteries3:before {
  content: "\e923";
  color: #fff;
}
.calc-batteries4:before {
  content: "\e924";
  color: #fff;
}
.calc-batteries5:before {
  content: "\e925";
  color: #fff;
}
.calc-bed1-active:before {
  content: "\e926";
  color: #fff;
}
.calc-bed1:before {
  content: "\e927";
  color: #fff;
}
.calc-bed2-active:before {
  content: "\e928";
  color: #fff;
}
.calc-bed2:before {
  content: "\e929";
  color: #fff;
}
.calc-bed3-active:before {
  content: "\e92a";
  color: #fff;
}
.calc-bed3:before {
  content: "\e92b";
  color: #fff;
}
.calc-bell:before {
  content: "\e92c";
  color: #fff;
}
.calc-bell1-active:before {
  content: "\e92d";
  color: #fff;
}
.calc-bell1:before {
  content: "\e92e";
  color: #fff;
}
.calc-bell2-active:before {
  content: "\e92f";
  color: #fff;
}
.calc-bell2:before {
  content: "\e930";
  color: #fff;
}
.calc-bell3-active:before {
  content: "\e931";
  color: #fff;
}
.calc-bell3:before {
  content: "\e932";
  color: #fff;
}
.calc-bluetooth-active:before {
  content: "\e933";
  color: #fff;
}
.calc-bluetooth:before {
  content: "\e934";
  color: #fff;
}
.calc-bluray2-active:before {
  content: "\e935";
  color: #fff;
}
.calc-bluray2:before {
  content: "\e936";
  color: #fff;
}
.calc-bluray3-active:before {
  content: "\e937";
  color: #fff;
}
.calc-bluray3:before {
  content: "\e938";
  color: #fff;
}
.calc-boardroom1:before {
  content: "\e939";
  color: #fff;
}
.calc-boardroom2:before {
  content: "\e93a";
  color: #fff;
}
.calc-boardroom3:before {
  content: "\e93b";
  color: #fff;
}
.calc-boardroom4:before {
  content: "\e93c";
  color: #fff;
}
.calc-boardroom5:before {
  content: "\e93d";
  color: #fff;
}
.calc-camera2-active:before {
  content: "\e93e";
  color: #fff;
}
.calc-camera2:before {
  content: "\e93f";
  color: #fff;
}
.calc-camera3-active:before {
  content: "\e940";
  color: #fff;
}
.calc-camera3:before {
  content: "\e941";
  color: #fff;
}
.calc-change-password:before {
  content: "\e942";
  color: #fff;
}
.calc-chat-active:before {
  content: "\e943";
  color: #00afc1;
}
.calc-chat:before {
  content: "\e944";
  color: #fff;
}
.calc-chats-active:before {
  content: "\e945";
}
.calc-chats-circle-on .path1:before {
  content: "\e946";
  color: rgb(0, 175, 193);
}
.calc-chats-circle-on .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-chats-circle-on .path3:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-chats-circle-on .path4:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-chats-circle-on .path5:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-chats-circle:before {
  content: "\e94b";
  color: #00afc1;
}
.calc-chats:before {
  content: "\e94c";
  color: #00afc1;
}
.calc-chromecast:before {
  content: "\e94d";
  color: #fff;
}
.calc-close .path1:before {
  content: "\e94e";
  color: rgb(193, 39, 45);
}
.calc-close .path2:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-cool:before {
  content: "\e950";
  color: #fff;
}
.calc-delete:before {
  content: "\e951";
  color: #c1272d;
}
.calc-door1-active:before {
  content: "\e952";
  color: #fff;
}
.calc-door1:before {
  content: "\e953";
  color: #fff;
}
.calc-door2-active:before {
  content: "\e954";
  color: #fff;
}
.calc-door2:before {
  content: "\e955";
  color: #fff;
}
.calc-door3-active:before {
  content: "\e956";
  color: #fff;
}
.calc-door3:before {
  content: "\e957";
  color: #fff;
}
.calc-door4-active:before {
  content: "\e958";
  color: #fff;
}
.calc-door4:before {
  content: "\e959";
  color: #fff;
}
.calc-door5-active:before {
  content: "\e95a";
  color: #fff;
}
.calc-door5:before {
  content: "\e95b";
  color: #fff;
}
.calc-doorlock1-active:before {
  content: "\e95c";
  color: #fff;
}
.calc-doorlock1:before {
  content: "\e95d";
  color: #fff;
}
.calc-doorlock2-active:before {
  content: "\e95e";
  color: #fff;
}
.calc-doorlock2:before {
  content: "\e95f";
  color: #fff;
}
.calc-doorlock3-active:before {
  content: "\e960";
  color: #fff;
}
.calc-doorlock3:before {
  content: "\e961";
  color: #fff;
}
.calc-down-circle-on .path1:before {
  content: "\e962";
  color: rgb(0, 0, 0);
}
.calc-down-circle-on .path2:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(20, 85, 130);
}
.calc-down-circle-on .path3:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-down:before {
  content: "\e965";
  color: #fff;
}
.calc-edit:before {
  content: "\e966";
  color: #fff;
}
.calc-error .path1:before {
  content: "\e967";
  color: rgb(193, 39, 45);
}
.calc-error .path2:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-error .path3:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-eye-close:before {
  content: "\e96a";
  color: #00afc1;
}
.calc-eye-open:before {
  content: "\e96b";
  color: #00afc1;
}
.calc-failed .path1:before {
  content: "\e96c";
  color: rgb(193, 39, 45);
}
.calc-failed .path2:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-fan1-active:before {
  content: "\e96e";
  color: #fff;
}
.calc-fan1:before {
  content: "\e96f";
  color: #fff;
}
.calc-fan2-active:before {
  content: "\e970";
  color: #fff;
}
.calc-fan2:before {
  content: "\e971";
  color: #fff;
}
.calc-fan3-active:before {
  content: "\e972";
  color: #fff;
}
.calc-fan3:before {
  content: "\e973";
  color: #fff;
}
.calc-forward:before {
  content: "\e974";
  color: #fff;
}
.calc-friend-accept:before {
  content: "\e975";
  color: #00afc1;
}
.calc-friend-reject:before {
  content: "\e976";
  color: #f00;
}
.calc-friend-resend:before {
  content: "\e977";
  color: #0ab0d2;
}
.calc-friend-revoke:before {
  content: "\e978";
  color: #f00;
}
.calc-friend-unfriend:before {
  content: "\e979";
  color: #f00;
}
.calc-friends-active:before {
  content: "\e97a";
  color: #00afc1;
}
.calc-friends-revoke:before {
  content: "\e97b";
  color: #f00;
}
.calc-friends:before {
  content: "\e97c";
  color: #fff;
}
.calc-gate1-active:before {
  content: "\e97d";
  color: #fff;
}
.calc-gate1:before {
  content: "\e97e";
  color: #fff;
}
.calc-gate2-active:before {
  content: "\e97f";
  color: #fff;
}
.calc-gate2:before {
  content: "\e980";
  color: #fff;
}
.calc-gate3-active:before {
  content: "\e981";
  color: #fff;
}
.calc-gate3:before {
  content: "\e982";
  color: #fff;
}
.calc-gate4-active:before {
  content: "\e983";
  color: #fff;
}
.calc-gate4:before {
  content: "\e984";
  color: #fff;
}
.calc-google:before {
  content: "\e985";
  color: #fff;
}
.calc-help:before {
  content: "\e986";
  color: #fff;
}
.calc-hifi1-active:before {
  content: "\e987";
  color: #fff;
}
.calc-hifi1:before {
  content: "\e988";
  color: #fff;
}
.calc-hifi2-active:before {
  content: "\e989";
  color: #fff;
}
.calc-hifi2:before {
  content: "\e98a";
  color: #fff;
}
.calc-hifi3-active:before {
  content: "\e98b";
  color: #fff;
}
.calc-hifi3:before {
  content: "\e98c";
  color: #fff;
}
.calc-home-active:before {
  content: "\e98d";
}
.calc-home-circle-on .path1:before {
  content: "\e98e";
  color: rgb(0, 175, 193);
}
.calc-home-circle-on .path2:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-home-circle-on .path3:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-home-circle:before {
  content: "\e991";
}
.calc-home:before {
  content: "\e992";
  color: #00afc1;
}
.calc-hub-active:before {
  content: "\e993";
}
.calc-hub-circle-on .path1:before {
  content: "\e994";
  color: rgb(0, 175, 193);
}
.calc-hub-circle-on .path2:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path3:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path4:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path5:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path6:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path7:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path8:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path9:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle-on .path10:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-hub-circle:before {
  content: "\e99e";
  color: #00afc1;
}
.calc-hub:before {
  content: "\e99f";
  color: #00afc1;
}
.calc-hubs:before {
  content: "\e9a0";
}
.calc-image-placeholder:before {
  content: "\e9a1";
}
.calc-info:before {
  content: "\e9a2";
  color: #fff;
}
.calc-item:before {
  content: "\e9a3";
  color: #fff;
}
.calc-itemfolder:before {
  content: "\e9a4";
  color: #fff;
}
.calc-kitchen1-active:before {
  content: "\e9a5";
  color: #fff;
}
.calc-kitchen1:before {
  content: "\e9a6";
  color: #fff;
}
.calc-kitchen2-active:before {
  content: "\e9a7";
  color: #fff;
}
.calc-kitchen2:before {
  content: "\e9a8";
  color: #fff;
}
.calc-kitchen3-active:before {
  content: "\e9a9";
  color: #fff;
}
.calc-kitchen3:before {
  content: "\e9aa";
  color: #fff;
}
.calc-kitchen4-active:before {
  content: "\e9ab";
  color: #fff;
}
.calc-kitchen4:before {
  content: "\e9ac";
  color: #fff;
}
.calc-lamp1-active:before {
  content: "\e9ad";
  color: #fff;
}
.calc-lamp1:before {
  content: "\e9ae";
  color: #fff;
}
.calc-lamp2-active:before {
  content: "\e9af";
  color: #fff;
}
.calc-lamp2:before {
  content: "\e9b0";
  color: #fff;
}
.calc-lamp3-active:before {
  content: "\e9b1";
  color: #fff;
}
.calc-lamp3:before {
  content: "\e9b2";
  color: #fff;
}
.calc-less:before {
  content: "\e9b3";
  color: #00afc1;
}
.calc-less2 .path1:before {
  content: "\e9b4";
  color: rgb(0, 175, 193);
}
.calc-less2 .path2:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-lg-tv1:before {
  content: "\e9b6";
  color: #fff;
}
.calc-lg-tv2:before {
  content: "\e9b7";
  color: #fff;
}
.calc-lg-tv3:before {
  content: "\e9b8";
  color: #fff;
}
.calc-lg-tv4:before {
  content: "\e9b9";
  color: #fff;
}
.calc-lg-tv5:before {
  content: "\e9ba";
  color: #fff;
}
.calc-light1-active:before {
  content: "\e9bb";
  color: #fff;
}
.calc-light1:before {
  content: "\e9bc";
  color: #fff;
}
.calc-light2-active:before {
  content: "\e9bd";
  color: #fff;
}
.calc-light2:before {
  content: "\e9be";
  color: #fff;
}
.calc-light3-active:before {
  content: "\e9bf";
  color: #fff;
}
.calc-light3:before {
  content: "\e9c0";
  color: #fff;
}
.calc-light4-active:before {
  content: "\e9c1";
  color: #fff;
}
.calc-light4:before {
  content: "\e9c2";
  color: #fff;
}
.calc-livingroom1:before {
  content: "\e9c3";
  color: #fff;
}
.calc-livingroom2:before {
  content: "\e9c4";
  color: #fff;
}
.calc-livingroom3:before {
  content: "\e9c5";
  color: #fff;
}
.calc-livingroom4:before {
  content: "\e9c6";
  color: #fff;
}
.calc-livingroom5:before {
  content: "\e9c7";
  color: #fff;
}
.calc-lock1:before {
  content: "\e9c8";
  color: #fff;
}
.calc-lock2:before {
  content: "\e9c9";
  color: #fff;
}
.calc-lock3:before {
  content: "\e9ca";
  color: #fff;
}
.calc-lock4:before {
  content: "\e9cb";
  color: #fff;
}
.calc-lock5:before {
  content: "\e9cc";
  color: #fff;
}
.calc-media:before {
  content: "\e9cd";
  color: #fff;
}
.calc-menu:before {
  content: "\e9ce";
  color: #fff;
}
.calc-minus:before {
  content: "\e9cf";
  color: #fff;
}
.calc-minus2:before {
  content: "\e9d0";
  color: #00afc1;
}
.calc-monitor1-active:before {
  content: "\e9d1";
  color: #fff;
}
.calc-monitor1:before {
  content: "\e9d2";
  color: #fff;
}
.calc-monitor2-active:before {
  content: "\e9d3";
  color: #fff;
}
.calc-monitor2:before {
  content: "\e9d4";
  color: #fff;
}
.calc-more:before {
  content: "\e9d5";
  color: #00afc1;
}
.calc-motionsensor1:before {
  content: "\e9d6";
  color: #fff;
}
.calc-motionsensor2:before {
  content: "\e9d7";
  color: #fff;
}
.calc-motionsensor3:before {
  content: "\e9d8";
  color: #fff;
}
.calc-motionsensor4:before {
  content: "\e9d9";
  color: #fff;
}
.calc-motionsensor5:before {
  content: "\e9da";
  color: #fff;
}
.calc-mute:before {
  content: "\e9db";
  color: #fff;
}
.calc-next:before {
  content: "\e9dc";
  color: #fff;
}
.calc-notifications:before {
  content: "\e9dd";
  color: #fff;
}
.calc-off-circle-on .path1:before {
  content: "\e9de";
  color: rgb(193, 39, 45);
}
.calc-off-circle-on .path2:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-off-circle-on .path3:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-off:before {
  content: "\e9e1";
  color: #fff;
}
.calc-options:before {
  content: "\e9e2";
  color: #fff;
}
.calc-pause:before {
  content: "\e9e3";
  color: #fff;
}
.calc-photo-upload-circle-on .path1:before {
  content: "\e9e4";
  color: rgb(0, 175, 193);
}
.calc-photo-upload-circle-on .path2:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.calc-photo-upload:before {
  content: "\e9e6";
}
.calc-play-apple:before {
  content: "\e9e7";
  color: #fff;
}
.calc-play-vodafone:before {
  content: "\e9e8";
  color: #fff;
}
.calc-play:before {
  content: "\e9e9";
  color: #fff;
}
.calc-plus:before {
  content: "\e9ea";
  color: #00afc1;
}
.calc-plus2:before {
  content: "\e9eb";
  color: #fff;
}
.calc-privacy-active:before {
  content: "\e9ec";
  color: #00afc1;
}
.calc-privacy:before {
  content: "\e9ed";
  color: #fff;
}
.calc-profile-active:before {
  content: "\e9ee";
  color: #00afc1;
}
.calc-profile-circle .path1:before {
  content: "\e9ef";
  color: rgb(0, 175, 193);
}
.calc-profile-circle .path2:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.calc-profile-edit:before {
  content: "\e9f1";
  color: #fff;
}
.calc-profile:before {
  content: "\e9f2";
  color: #fff;
}
.calc-push-off .path1:before {
  content: "\e9f3";
  color: rgb(255, 255, 255);
}
.calc-push-off .path2:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-push-off .path3:before {
  content: "\e9f5";
  margin-left: -1em;
  color: rgb(20, 85, 130);
}
.calc-push-off .path4:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-push-on .path1:before {
  content: "\e9f7";
  color: rgb(0, 175, 193);
}
.calc-push-on .path2:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-push-on .path3:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-push-on .path4:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(20, 85, 130);
}
.calc-push-on .path5:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-radio-active:before {
  content: "\e9fc";
  color: #fff;
}
.calc-radio:before {
  content: "\e9fd";
  color: #fff;
}
.calc-refresh:before {
  content: "\e9fe";
  color: #fff;
}
.calc-refresh1 .path1:before {
  content: "\e9ff";
  color: rgb(0, 175, 193);
}
.calc-refresh1 .path2:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-refresh1 .path3:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-remote-apple:before {
  content: "\ea02";
  color: #fff;
}
.calc-remote-sky:before {
  content: "\ea03";
  color: #fff;
}
.calc-remote-tv:before {
  content: "\ea04";
  color: #fff;
}
.calc-remote-vodafone:before {
  content: "\ea05";
  color: #fff;
}
.calc-remove:before {
  content: "\ea06";
  color: #f00;
}
.calc-return:before {
  content: "\ea07";
  color: #fff;
}
.calc-rollergate1-active:before {
  content: "\ea08";
  color: #fff;
}
.calc-rollergate1:before {
  content: "\ea09";
  color: #fff;
}
.calc-rollergate2-active:before {
  content: "\ea0a";
  color: #fff;
}
.calc-rollergate2:before {
  content: "\ea0b";
  color: #fff;
}
.calc-search:before {
  content: "\ea0c";
  color: #fff;
}
.calc-send:before {
  content: "\ea0d";
  color: #fff;
}
.calc-share:before {
  content: "\ea0e";
  color: #00afc1;
}
.calc-signout-active:before {
  content: "\ea0f";
  color: #00afc1;
}
.calc-signout:before {
  content: "\ea10";
  color: #fff;
}
.calc-sky-tv1:before {
  content: "\ea11";
  color: #fff;
}
.calc-sky-tv3-229:before {
  content: "\ea12";
  color: #fff;
}
.calc-sky-tv3-230:before {
  content: "\ea13";
  color: #fff;
}
.calc-sky-tv4:before {
  content: "\ea14";
  color: #fff;
}
.calc-skylogo:before {
  content: "\ea15";
  color: #fff;
}
.calc-sound-active:before {
  content: "\ea16";
  color: #fff;
}
.calc-sound:before {
  content: "\ea17";
  color: #fff;
}
.calc-success .path1:before {
  content: "\ea18";
  color: rgb(0, 175, 193);
}
.calc-success .path2:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(20, 33, 51);
}
.calc-support-active:before {
  content: "\ea1a";
  color: #00afc1;
}
.calc-support:before {
  content: "\ea1b";
  color: #fff;
}
.calc-switch1:before {
  content: "\ea1c";
  color: #fff;
}
.calc-switch2:before {
  content: "\ea1d";
  color: #fff;
}
.calc-switch3:before {
  content: "\ea1e";
  color: #fff;
}
.calc-switch4:before {
  content: "\ea1f";
  color: #fff;
}
.calc-switch5:before {
  content: "\ea20";
  color: #fff;
}
.calc-switch6:before {
  content: "\ea21";
  color: #fff;
}
.calc-switch7:before {
  content: "\ea22";
  color: #fff;
}
.calc-switch8:before {
  content: "\ea23";
  color: #fff;
}
.calc-tag:before {
  content: "\ea24";
  color: #fff;
}
.calc-temp1-active:before {
  content: "\ea25";
  color: #fff;
}
.calc-temp1:before {
  content: "\ea26";
  color: #fff;
}
.calc-temp2-active:before {
  content: "\ea27";
  color: #fff;
}
.calc-temp2:before {
  content: "\ea28";
  color: #fff;
}
.calc-terms-active:before {
  content: "\ea29";
  color: #00afc1;
}
.calc-terms:before {
  content: "\ea2a";
  color: #fff;
}
.calc-ticked:before {
  content: "\ea2b";
}
.calc-ticked2:before {
  content: "\ea2c";
}
.calc-toggle-off:before {
  content: "\ea2d";
  color: #fff;
}
.calc-toggle-on .path1:before {
  content: "\ea2e";
  color: rgb(255, 255, 255);
}
.calc-toggle-on .path2:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(0, 175, 193);
}
.calc-toggle-on .path3:before {
  content: "\ea30";
  margin-left: -1em;
  color: none;
}
.calc-toggle-on .path4:before {
  content: "\ea31";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-tv-active:before {
  content: "\ea32";
  color: #fff;
}
.calc-tv:before {
  content: "\ea33";
  color: #fff;
}
.calc-tv0ff1:before {
  content: "\ea34";
  color: #fff;
}
.calc-tv0ff2:before {
  content: "\ea35";
  color: #fff;
}
.calc-tv0ff3:before {
  content: "\ea36";
  color: #fff;
}
.calc-tv1-active:before {
  content: "\ea37";
  color: #fff;
}
.calc-tv1:before {
  content: "\ea38";
  color: #fff;
}
.calc-tv2-active:before {
  content: "\ea39";
  color: #fff;
}
.calc-tv2:before {
  content: "\ea3a";
  color: #fff;
}
.calc-tvroom1:before {
  content: "\ea3b";
  color: #fff;
}
.calc-tvroom2:before {
  content: "\ea3c";
  color: #fff;
}
.calc-tvroom3:before {
  content: "\ea3d";
  color: #fff;
}
.calc-tvroom4:before {
  content: "\ea3e";
  color: #fff;
}
.calc-tvroom5:before {
  content: "\ea3f";
  color: #fff;
}
.calc-unfriend:before {
  content: "\ea40";
  color: #f00;
}
.calc-unshare:before {
  content: "\ea41";
  color: #00afc1;
}
.calc-unticked:before {
  content: "\ea42";
}
.calc-unticked2:before {
  content: "\ea43";
}
.calc-up-circle-on .path1:before {
  content: "\ea44";
  color: rgb(0, 0, 0);
}
.calc-up-circle-on .path2:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(20, 85, 130);
}
.calc-up-circle-on .path3:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-up:before {
  content: "\ea47";
  color: #fff;
}
.calc-user-placeholder:before {
  content: "\ea48";
  color: #fff;
}
.calc-ventelation1:before {
  content: "\ea49";
  color: #fff;
}
.calc-ventelation2:before {
  content: "\ea4a";
  color: #fff;
}
.calc-ventelation3:before {
  content: "\ea4b";
  color: #fff;
}
.calc-ventelation4:before {
  content: "\ea4c";
  color: #fff;
}
.calc-ventelation5:before {
  content: "\ea4d";
  color: #fff;
}
.calc-view:before {
  content: "\ea4e";
  color: #fff;
}
.calc-vodafonelogo:before {
  content: "\ea4f";
  color: #fff;
}
.calc-vodaphone-tv1:before {
  content: "\ea50";
  color: #fff;
}
.calc-vodaphone-tv2:before {
  content: "\ea51";
  color: #fff;
}
.calc-vodaphone-tv3:before {
  content: "\ea52";
  color: #fff;
}
.calc-vodaphone-tv4:before {
  content: "\ea53";
  color: #fff;
}
.calc-vodaphone-tv5:before {
  content: "\ea54";
  color: #fff;
}
.calc-warm:before {
  content: "\ea55";
  color: #fff;
}
.calc-warning-circle:before {
  content: "\ea56";
  color: #c1272d;
}
.calc-warning .path1:before {
  content: "\ea57";
  color: rgb(251, 174, 23);
}
.calc-warning .path2:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-warning .path3:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.calc-wifi-active:before {
  content: "\ea5a";
  color: #fff;
}
.calc-wifi:before {
  content: "\ea5b";
  color: #fff;
}
