.root {
  /* height: 170px; */
  height: 100%;
  background: var(--utility-White);
  box-shadow: 0px 5px 25px rgba(4, 134, 150, 0.1);
  border-radius: 10px;
  padding-bottom: 3%;
}
/* .root:hover {
  box-shadow: 5px 5px 5px rgba(102, 163, 97, 0.233);
} */
.imgBgStyle {
  background-repeat: no-repeat !important;
}
.data {
  /*margin-top: 20px;*/
  /*height: 100%;*/
  padding: 8px 0 10px 0;
  /* cursor: pointer; */
  /* margin: auto; */
}
.dataAir {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 5px;
  overflow: hidden;
}
.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 12px; */
  /* margin: auto; */
  color: var(--primary-dark-green);
  /* text-align: center; */
}

.type {
  font-family: Inter;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 12px;
  /* line-height: 19px; */
  /* margin: auto; */
  color: var(--utility-gray);
  /* text-align: center; */
}

.actionsMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
