.root {
  /* padding: 0; */
  /* margin: 10px; */
  background: var(--utility-White);
  box-shadow: 0px 5px 25px rgba(4, 134, 150, 0.1);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.photo {
  background: url("../../../assets/images/project-card-image.png");
  background-repeat: no-repeat !important;
  background-size: cover;
  width: 100%;
  border-right: 2px solid #e6f3f4;
}
.data {
  padding: 8px 10px 10px 10px;
}
.titleIcon {
  text-align: end;
  color: var(--utility-gray-text);
}
.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--utility-black);
}
.desciption {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: var(--utility-gray);
}

.circleAvatar {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-right: 8px;
}

.calcIconView:before {
  color: var(--primary-color);
  font-size: 20px;
}

.permissionType {
  /* Pharagraph (Small) */

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Light Green */

  color: #048696;
}

.desciption {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: var(--utility-black);
  margin-top: 2px;
}

.bottomLine {
  border-bottom: 1px solid var(--light-green) !important;
}
