.innerPageContent {
  display: flex;
}
.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}
.btnBar {
  display: flex;
}
.addInterface {
  /*width: 200px;*/
}
.btnBar {
  display: flex;
}
.flexSpacer {
  flex-grow: 1;
}
.sortBtn {
  padding-right: 12px;
}
.SortText {
  padding-left: 8px;
}

@media (max-width: 1030px) {
  .tabsTop {
    width: 80vw;
  }
}
