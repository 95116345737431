.dialogContent {
  padding: 0;
  min-width: 300px;
}

.DragSelectorListCont {
  padding: 0;
}
.DragSelectorListInnerCont {
  background: #fefefe;
  width: 100%;
  min-height: 200px;
}
.spacer {
  flex-grow: 1;
}
.DragSelectorItemCard {
  color: #aa3838;
  cursor: pointer;
  padding: 6px;
  background: #fefefe;
  display: flex;
  font-size: 15px;
}

.itemCardTitle {
  font-size: 15px;
  color: var(--primary-dark-green);
  padding: 0;
  margin: 0;
}
.itemCardSubTitle {
  font-size: 10px;
  color: var(--utility-gray);
}

.DragSelectorItemCard:hover {
  background: var(--light-green);
}
.DragSelectorItemCardDivider {
  padding: 1px 0px 0px;
  background: #888888;
}
.calcIconView {
  padding: 4px;
}
.calcIconView:before {
  color: var(--primary-color);
  font-size: 25px;
}
