.root {
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
  border-radius: 5px;
  cursor: pointer;
  padding: 13px 0px 13px 0;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.btnText {
  color: var(--buttonTextColor);
  display: inline;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none !important;
}

.btnDisabledText {
  color: var(--utility-gray-text);
  display: inline;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none !important;
}

.iconText {
  display: flex;
  margin-left: 10px;
  color: var(--buttonTextColor);
  /* transform: translateY(4px); */
  text-transform: none;
}

.btnBg {
  text-transform: none !important;
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
  color: var(--buttonTextColor);
  height: 48px;
  padding: 0 30px;
  width: 100%;
  font-size: 16px !important;
}

.btnDisabled {
  text-transform: none !important;
  background: var(--disabled-btn-bg) !important;
  border: 1px solid var(--light-green);
  border-radius: 5px;
  color: var(--utility-gray-text);
  height: 48px;
  padding: 0 30px;
  width: 100%;
  font-size: 16px !important;
}
