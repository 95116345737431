.DragSelectorListCont {
  padding: 8px;
}
.DragSelectorListInnerCont {
  background: #fefefe;
  width: 100%;
  min-height: 120px;
  /* overflow-y: scroll; */
}
.spacer {
  flex-grow: 1;
}
.DragSelectorItemCard {
  color: #aa3838;
  cursor: pointer;
  padding: 8px;
  background: #fefefe;
  display: flex;
}

.DragSelectorItemCard:hover {
  background: var(--light-green);
}
.DragSelectorItemCardDivider {
  padding: 1px 0px 0px;
  background: #888888;
}
