@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/css/bootstrap.css";
@import "./assets/css/bootstrap.min.css";
@import "./assets/calc-icons/style.css";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.textLink {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.textLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}
.colorPrimary {
  color: var(--primary-color) !important;
}
.flex-container {
  display: flex;
  flex-direction: row;
}

.main-content-header-secondary-text {
  color: var(--utility-gray-text);
  font-size: 24px;
  font-weight: 600;
}

/*WIZARD*/
.followingText {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin-left: 5%;
}

.wizardLine {
  height: 1px;
  background-color: var(--utility-gray-light);
  width: 20%;
}

.wizardTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
/*WIZARD*/

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.custom-link1 {
  color: var(--primary-dark-green) !important;
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 1em;
}

.custom-link2 {
  color: var(--light-green) !important;
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 1em;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.nopaddingOnly {
  padding: 0 !important;
}

.bottomLine {
  border-bottom: 1px solid var(--utility-background);
}

.custom-card {
  backdrop-filter: blur(2px);
  background-color: rgba(238, 241, 243, 0.9);
  width: 100%;
  border-radius: 10px;
  padding: 0px;
  flex: 1;
}

.main-content {
  border-radius: 10px;
  flex-grow: 10;
  padding: 0;
  min-height: 70vh;
  background: var(--green-light-bg);
  max-width: "100%" !important;
  height: 100%;
}

.custom-card-top-level {
  backdrop-filter: blur(2px);
  background-color: rgba(238, 241, 243, 0.9);
  width: 100%;
  border-radius: 10px;
  padding: 0px;
  flex: 1;
}

@media only screen and (max-width: 700px) {
  .mobile-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 1224px) {
  .custom-card {
    backdrop-filter: blur(2px);
    background-color: rgba(238, 241, 243, 0.9);
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    padding: 0px;
    flex: 1;
  }

  .main-content {
    border-radius: 10px;
    flex-grow: 10;
    padding: 0;
    height: 86vh;
    background: var(--green-light-bg);
    max-width: "100%" !important;
    height: 100%;
  }

  .custom-card-top-level {
    backdrop-filter: blur(2px);
    background-color: rgba(238, 241, 243, 0.9);
    width: 100%;
    height: 46vh;
    border-radius: 10px;
    padding: 0px;
    flex: 1;
  }
}

.custom-card-container {
}
.custom-card-in {
}
.custom-card-title {
  display: flex;
  color: var(--primary-dark-green);
  padding: 0px 0px 0px 30px;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
}
.custom-card-body {
  /* padding: 1em; */
}

.card-header-title-bg {
  /* background: #FFFFFF; */
  /* height: 60px; */
  /* width: 100%; */
  /* border-radius: 10px 10px 0px 0px;  */
}

/* card 2 */
.custom-card2 {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.9);
  /* opacity: 0.9; */
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 0px 15px 15px 15px;
  flex: 1;
}

.custom-card2-container {
}
.custom-card2-in {
}
.custom-card2-title {
  display: flex;
  width: 100%;
  color: var(--primary-dark-green);
  padding: 0px 0px 0px 0px;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid var(--green-30);
}

.header-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  width: 100%;
}

.custom-card2-body {
  /* padding: 1em; */
}
.cursor-pointer {
  cursor: pointer;
}

.card-header-title-bg {
  /* background: #FFFFFF; */
  /* height: 60px; */
  /* width: 100%; */
  /* border-radius: 10px 10px 0px 0px;  */
}

.heightFull {
  height: 100% !important;
}

/* margin styles */
.m-0 {
  margin-left: 0;
  margin-right: 0;
}
.m-1 {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}

.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}

.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px;
}

.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}

/*  padding styles */
.pt-05 {
  padding-left: 5px;
}
.pl-1 {
  padding-left: 10px;
}
.pl-2 {
  padding-left: 20px;
}
.pl-3 {
  padding-left: 30px;
}
.pl-4 {
  padding-left: 40px;
}
.pl-5 {
  padding-left: 50px;
}

.pt-05 {
  padding-right: 5px;
}
.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 20px;
}
.pr-3 {
  padding-right: 30px;
}
.pr-4 {
  padding-right: 40px;
}
.pr-5 {
  padding-right: 50px;
}

.pt-05 {
  padding-top: 5px;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pt-3 {
  padding-top: 30px;
}
.pt-4 {
  padding-top: 40px;
}
.pt-5 {
  padding-top: 50px;
}

.pt-05 {
  padding-bottom: 5px;
}
.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.pb-3 {
  padding-bottom: 30px;
}
.pb-4 {
  padding-bottom: 40px;
}
.pb-5 {
  padding-bottom: 50px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.width-full-fixed {
  width: 100% !important;
  margin: 0 auto;
}

.tab-in-container {
  max-height: 450px !important;
  overflow: auto;
}

.projectPhotom {
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 200px !important;
  width: 90% !important;
  border-right: 2px solid #e6f3f4;
  border-radius: 10px;
  margin: 0px 2em 2em;
}

.background-color-light {
  background-color: #ffffff;
  opacity: 0.9;
}

.container-title-dark-txt {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: var(--utility-black);
}

.container-title-dark-green-txt {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: var(--primary-dark-green);
}

.text-center {
  text-align: center;
}

.form-input-text {
  width: 100%;
  padding: 15px 20px 0px 20px;
  box-sizing: border-box;
  border: 1px solid #d9dfe0;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  color: #06454d;
  font-weight: normal;
  resize: none;
  outline: none;
}
.form-input-textarea {
  width: 100%;
  height: 200px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #d9dfe0;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  color: #06454d;
  font-weight: normal;
  resize: none;
  outline: none;
}
.flex {
  display: flex !important;
}

.dashboard-content {
  border-radius: 10px;
  flex-grow: 10;
  padding: 0;
  /* min-height: 100%; */
  background: #ffffff17;
  max-width: "100%" !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.text-center {
  text-align: center;
}
.pt-16 {
  padding-top: 16px;
}
.pl-8 {
  padding-left: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}
.text-error {
  color: var(--accent-primary);
  font-size: 10px;
  margin-bottom: 0px;
  padding-left: 8px;
}

.pulsating-circle {
  position: absolute;
  right: -16px;
  top: -8px;
  /* transform: translateX(-50%) translateY(-50%); */
  width: 60px;
  height: 60px;
  background-color: #0aff0a;
  animation: pulse-ring 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

/* .pulsating::before {
  content: '';
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: red;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating::after {
  content: '';
  position: absolute;
  left: 0; 
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
} */

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
    border-radius: 45px;
  }
  80%, 100% {
    opacity: 0;
    border-radius: 45px;
  }
}

@keyframes pulse-dot {
  0% {
    display: block;
    transform: scale(.8);
  }
  50% {
    display: block;
    transform: scale(1);
  }
  100% {
    display: block;
    transform: scale(.8);
  }
}