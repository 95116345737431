.hub_apis_item {
  background-color: white;
  width: 100%;
  padding: 2%;
  margin: 2% auto;
}

.hub_apis_ssh_link {
  background-color: #222222;
  color: #3ffc6e;
  padding: 1%;
  font-family: monospace;
}

.hub_apis_host_link {
  padding: 1%;
  color: #222222 !important;
  text-decoration: underline;
}
