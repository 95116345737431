.root {
  height: 150px;
  /* padding: 0 0 20px 0; */
  /* margin: 10px; */
  background: var(--utility-White);
  /* box-shadow: 0px 5px 25px rgba(4, 134, 150, 0.1); */
  border-radius: 5px;
  color: var(--utility-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1 0 21%; */
}
.root:hover {
  /* box-shadow: 5px 5px 5px rgba(102, 163, 97, 0.233); */
  transition: all 0.5s;
  /* transform: scale(1.01); */
}
.iconCont {
  color: "black";
  background: #216371;
  padding: 2%;
  border-radius: 5px;
  text-align: center;
}
.projectPhoto {
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: center;
  height: 50%;
  width: 50%;
  margin: auto;
  padding-top: 20px;
}
.data {
  padding: 8px 10px 10px 10px;
  margin: auto;
}
.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: auto;
  color: var(--utility-black);
  text-align: center;
}
