.bg1,
.bg2 {
  position: absolute;
  width: 100%;
  height: 100vh !important;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;

}

.bg1 {
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.03);
  width: 100%;
  float: left;
  height: 100%;
  margin: 0 !important;
}

.bg2 {
  background-image: url("../../../assets/images/brand_pattern1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.circularLoader {
  width: 60px !important;
  height: 60px !important;
  color: #fefefe !important;
  /* color: #fefefe !important; */
}
.logoWithName {
  background-image: url("../../../assets/images/Smartlife-invert-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  margin-top: 40px;
  width: 220px;
  height: 80px;
}
.loadingContainer {
  text-align: center;
  margin: 50px;
}
.titleDarkText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffffaf;
  opacity: 0.5;
  padding-top: 100px;
}
.content {
  margin: auto;
  text-align: center;
}
