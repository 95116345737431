.root {
    height: 100%;
    padding: 10px 0;
    background: var(--utility-White);
    box-shadow: 0px 5px 25px rgba(4, 134, 150, 0.1);
    border-radius: 10px;
    /* flex: 1 0 21%; */
  }
  .root:hover {
    cursor: pointer;
    box-shadow: 5px 5px 5px rgba(102, 163, 97, 0.233);
  }
  .imgBgStyle {
    background-repeat: no-repeat !important;
    background-size: contain;
    background-position: center;
    height: 50%;
    width: 100%;
    margin: auto;
    padding-top: 20px;
  }
  .data {
    padding: 8px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* margin: auto; */
  }
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 12px; */
    /* margin: auto; */
    color: var(--primary-dark-green);
    /* text-align: center; */
  }
  .type {
    font-family: Inter;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
    /* line-height: 19px; */
    /* margin: auto; */
    color: var(--utility-gray);
    /* text-align: center; */
  }
  