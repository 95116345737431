.root {
  background: #fefefe !important;
  overflow-y: scroll !important;
}
.innerPageContent {
  display: flex;
}
.cont {
  padding: 12px !important ;
}
.cont2 {
  padding: 8px 30px !important ;
}

.submitCont {
  margin: 10px 10px 10px 10px;
  padding-top: 15px;
  display: flex;
  align-items: center;
}
.zeroPad {
  padding-left: 0 !important;
}

.loadingCom {
  padding: 10px;
}
.deviceDescriptionPath {
  padding-left: 24px;
}
