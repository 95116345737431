.root {
  /* margin-top: 30px; */
  padding: 20px;
  padding-left: 16px;
  padding-right: 16px;
  background: var(--utility-White);
  border-radius: 15px;
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: left;
}

/* Header screen sizes :.:.:.:.:.:.:.:.:.:.:.: */
@media only screen and (min-width: 750px) {
  /* .root {
    height: 80px;
  } */
}
/* Header screen sizes :.:.:.:.:.:.:.:.:.:.:.: */
/*@media only screen and (max-width : 1530px) {*/
/*  .root {*/
/*    height: 80px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width : 1024px) {*/
/*  .root {*/
/*    height: 70px;*/
/*    margin-top: 0px !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width : 768px) {*/
/*  .root {*/
/*    height: 90px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width : 640px) {*/
/*  .root {*/
/*    height: 90px;*/
/*  }*/
/*}*/

/* @media only screen and (max-width: 425px) {
  .root {
    height: 60px;
  }
}

@media only screen and (max-width: 320px) {
  .root {
    height: 60px;
  }
} */

/* End :.:.:.:.:.:.:.:.:.:.:.: */

.logoContent {
  display: flex;
}

.logoImage {
  background: url("../../../assets/images/Smartlife-main-logo@2x.png");
  background-repeat: no-repeat !important;
  background-size: contain;
  min-width: 180px;
  height: 43px;
  margin-right: 43px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

@media only screen and (max-width: 1024px) {
  .logoImage {
    display: none;
  }
  .logoImage {
    background: url("../../../assets/images/Smartlife-fav.png");
    background-repeat: no-repeat !important;
    background-size: contain;
    min-width: 43px;
    height: 43px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
  }
}

.logoImage:hover {
  cursor: pointer;
}

.flexSpacer {
  flex-grow: 1;
}

.profileTag {
  border-left: 2px solid var(--green-10);
  border-right: 2px solid var(--green-10);
  padding: 0 12px 0 12px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .profileTag {
    display: none;
  }
}

.projectSelectorMenuMobile {
  display: none;
}

@media only screen and (max-width: 718px) {
  .projectSelectorMenu {
    display: none;
  }

  .projectSelectorMenuMobile {
    display: block;
  }
}

.circleAvatar {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-right: 8px;
}

.userNameBtn {
  color: var(--utility-gray-text);
}

.notificationBtn {
  padding-left: 16px;
  padding-right: 12px;
  color: var(--primary-dark-green) !important;
  display: flex;
}

@media only screen and (max-width: 425px) {
  .notificationBtn {
    display: flex;
    align-items: center;
    border-left: 2px solid var(--green-10);
    height: 40px;
  }
  .bellNotification {
    margin-left: 4px;
  }
}

.headerBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-left: 2px solid var(--green-10);
  padding-left: 20px;
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.headerLeft:hover {
  background: #ffffff;
  opacity: 0.9;
  backdrop-filter: blur(2px);
}

.headerLocation {
  color: var(--utility-gray-text);
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  margin-left: 10px;
}

.headerIcon {
  cursor: pointer;
  height: 60px;
}

.itemLocation {
  color: var(--primary-dark-green);
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  margin-left: 10px;
}

/*.itemDropdown {*/
/*  padding-left: 29px;*/
/*  color: var(--primary-dark-green) !important;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  cursor: pointer;*/
/*}*/
.itemDropdown {
}

.headerSearch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.searchField {
  padding: 10px 10px 10px 10px;
}

.menuItem {
  width: 350px !important;
  font-family: "Inter", sans-serif;
  color: var(--primary-dark-green) !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.menuCardImage {
  width: 30% !important;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
}

.menuCardTitle {
  margin-left: 20px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 425px) {
  .leftItem {
    display: none;
  }
}

/*@media only screen and (max-width : 768px) {*/
/*  .headerLocation {*/
/*    display: none;*/
/*    white-space: nowrap;*/
/*    text-overflow: ellipsis;*/
/*    overflow: hidden;*/
/*  }*/
/*}*/

@media only screen and (max-width: 768px) {
  .leftItem {
    display: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media only screen and (min-width: 0px) {
  .mobileHeader {
    width: 100%;
    height: 60px;
    background: var(--utility-White);
    z-index: 3;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .headerTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: var(--primary-dark-green);
    padding-left: 15px;
  }
  .dropDown {
    padding-right: 5px;
  }
}

@media only screen and (min-width: 2560px) {
  .mobileHeader {
    display: none;
  }
}

@media only screen and (min-width: 1440px) {
  .mobileHeader {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .mobileHeader {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .mobileHeader {
    display: none;
  }
}

@media only screen and (min-width: 2560px),
  (min-width: 1440px),
  (min-width: 1024px),
  (min-width: 768px) {
  .menuIcon {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .menuIcon {
    margin-right: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-dark-green);
  }
}

@media only screen and (max-width: 425px), (max-width: 768px) {
  .headerSearch {
    display: none;
  }
}
