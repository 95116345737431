.root {
}
.innerPageContent {
  display: flex;
}
.photoForm {
  flex-grow: 1;
  align-items: center;
  padding: 10px;
}
.profilePohoto {
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover;
  width: 200px;
  /* height: 200px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.uploadInput {
  display: none;
  position: absolute;
}
.uploadButton {
  margin-top: 2%;
  width: 100%;
  text-align: center;
  position: relative;
  top: 80%;
}
.profileForm {
  flex-grow: 5;
  padding-top: 10px;
}
.emailText {
  padding: 10px 10px 10px 0;
}

.buttonBar {
  align-items: center;
  margin-top: 10px;
}
.headerRoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5% 3%;
  width: 100%;
}
headerTitle {
  font-weight: '600';
  color: var(--primary-dark-green);
  font-size: 2rem;
}
contentRoot {
  flex: 1;
  margin: 2%;
  overflow: scroll;
  overflow-x: hidden;
}