.uv-ui-form-control {
  margin: 8px !important;
  min-width: 110px !important;
}
.uv-ui-form-control-sizing {
  min-width: 220px;
}
.mt-8 {
  margin-top: 20px !important;
}
.ml-8 {
  margin-left: 20px !important;
}
.uv-ui-form-features {
  margin: 8px !important;
}

.uv-ui-form-control-full-width {
  width: 100%;
}
.uv-ui-form-control-text-center {
  text-align: center;
}
.uv-ui-form-zone-item {
  padding-top: 8px;
}
.pro-device-dynamic-items {
  padding: 8px;
}
.json-field-card-parent {
  padding-left: 10px !important;
}
.json-field-card {
  padding: 8px;
  border: 1px solid;
  border-color: var(--utility-gray1);
  border-radius: 4px;
  font-size: 10px;
}

.field-enable-card {
  padding: 8px;
  background-color: var(--utility-background);
  margin-bottom: 5px;
}
.field-enable-card-data {
  border-color: var(--utility-gray1);
  border-radius: 4px;
}
