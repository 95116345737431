.innerPageContent {
  display: flex;
}
.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}
.fieldSpace {
  margin: 8px 0 8px 0 !important;
  width: 100%;
}

.inputFeedback {
  color: red;
  margin-top: 0.25rem;
}

.title-icon {
  font-size: 24px;
}

.deviceName {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: var(--primary-dark-green);
}

.devicePath {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: var(--utility-gray-text);
}

.bottomLine {
  border-bottom: 1px solid var(--light-green) !important;
}

.contentHeader {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-dark-green);
}
