.root {
  padding: 10px 0;
  margin: auto;
  /* background: var(--utility-White); */
  border-bottom: 1px solid var(--green-30);
  display: flex;
  align-items: center;
}
.root:hover {
  cursor: pointer;
  /* box-shadow: 5px 5px 5px rgba(102, 163, 97, 0.233); */
}
.projectPhoto {
  background-repeat: no-repeat !important;
  background-size: contain;
  background-position: center;
  height: 50%;
  width: 50%;
  margin: auto;
  padding-top: 20px;
}
.data {
  padding: 8px 10px 10px 10px;
  margin: auto;
}
.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: auto;
  color: var(--primary-dark-green);
  text-align: left;
}
.circleAvatar {
  width: 45px;
  height: 45px;
  border: 1px solid var(--green-90);
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin-right: 8px;
  align-content: center;
  position: relative;
  margin-bottom: 8px;
  padding: 15%;
}
.img {
  width: 100%;
  height: 100%;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */
}
.date {
  /* Pharagraph (Tiny) */
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;

  /* Utility/Black */
  color: var(--utility-dark-gray);
}
