.innerPageContent {
  display: flex;
}
.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}

.fieldSpace {
  margin: 8px 0 8px 0 !important;
  width: 100%;
  font-size: 30px !important;
}
