.root {
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
  border-radius: 5px;
  cursor: pointer;
  padding: 13px 0px 13px 0;
  margin: auto;
  margin-top: 12px !important;
  margin-bottom: 8px;
  width: 100%;
}

.btnText {
  color: var(--buttonTextColor);
  display: inline;
}
.iconText {
  margin-left: 10px;
  color: var(--buttonTextColor);
  transform: translateY(4px);
}

.btnBg {
  background: linear-gradient(239.95deg, #048696 7.54%, #06454d 92.1%);
  border: 0;
  border-radius: 5px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
  color: var(--buttonTextColor);
  padding-left: 14px !important;
  padding-right: 14px !important;
}
