.root {
}
.innerPageContent {
  display: flex;
}
.photoForm {
  flex-grow: 1;
  align-items: center;
  padding: 10px;
}
.profilePohoto {
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: cover;
  width: 200px;
  height: 200px;
  margin: auto;
}
.uploadInput {
  display: none;
  position: absolute;
}
.uploadButton {
  width: 100%;
  text-align: center;
  position: relative;
  top: 80%;
}
.profileForm {
  flex-grow: 5;
  padding-top: 10px;
}
.emailText {
  padding: 10px 10px 10px 0;
}
