.innerPageContent {
  display: flex;
}
.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}

.projectTyleSelector{
  border: 3px solid var(--primary-color);
  border-radius: 5px;
  padding: 5px 5px;
}
