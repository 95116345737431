.root {
  padding: 10px;
  border-bottom: 1px solid var(--primary-dark-green-light);
  margin-right: 20px;
  width: 100%;
}
.form {
  display: flex;
}

.form > * {
  padding-right: 10px;
}

.sortText {
  color: var(--utility-gray);
  font-weight: 500;
  font-size: 16px;
  /* margin-top: 100px !important; */
}

.dropDownText {
  color: var(--primary-dark-green);
  font-weight: 500;
  font-size: 15px;
  margin: 0 5px;
  vertical-align: top;
}

.iconSize {
  width: 20px !important;
  height: 20px !important;
  margin: 5px 5px;
}

/* .filterOptions {
  display: flex !important;
  flex-direction: row !important;
} */
