.itemsList {
  padding: 10px 10px 20px 0px;
  overflow-y: scroll;
  /* min-height: 300px;
  max-height: 60vh; */
  overflow: auto;
}
.pathList {
  display: flex;
}
.pathSep {
  font-size: 18px !important;
  padding-right: 4px;
}
.pathDisplayName {
  font-size: 18px !important;
}
.pathItem {
  cursor: pointer;
  padding: 8px 2px 8px;
  font-weight: 600;
}
.pathItem:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.pathItem.active {
  color: var(--primary-color);
}
.flexSpacer {
  flex-grow: 1;
}
