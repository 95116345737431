.innerPageContent {
  display: flex;
}
.projectList {
  /* margin-left: 1%;   */
  /* display: flex; */
  /* flex: 1; */
  /* flex-direction: row; */
}
.fieldSpace {
  margin: 8px 0 8px 0 !important;
  width: 100%;
}

.inputFeedback {
  color: red;
  margin-top: .25rem;
}