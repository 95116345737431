.innerPageContent {
  display: flex;
}
.cont {
  padding: 12px !important ;
  background-color: white;
  border-radius: 13px;
}

.submitCont {
  margin: 10px 10px 10px 10px;
  padding-top: 15px;
}
.zeroPad {
  padding-left: 0 !important;
}
.root {
  background: #fefefe !important;
  height: 100%;
  border-radius: 13px;
}
